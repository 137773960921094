import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useDeleteTrip = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useTypedAsyncFn(async (tripId: string) => {
    const promise = webClient.delete(`/trips/${tripId}`);
    toast.promise(promise, {
      success: t("delete.success"),
      error: t("delete.error"),
      loading: t("delete.loading")
    });
  }, []);
};
