import React, { FC, useState, useEffect, useRef } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { COLORS, FONT } from "../../UI/Colors";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Range } from "react-date-range";
import Joyride, { CallBackProps, Step } from "react-joyride";
import WithHeader from "../../Layout/WithHeader";
import WithFooter from "../../Layout/WithFooter";
import { getUserToken } from "../../reducers/auth.reducers";
import { Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import Map, { MapRef } from "react-map-gl";
import { useFetchUser } from "../../hooks/useFetchUser";

const cardData = [
  {
    id: 1,
    title: "Seoul, South Korea",
    content: "travel.cardContentSeoul",
    imageUrl: "/seoul.jpg",
    city: "Seoul",
    lat: 37.566,
    lon: 126.9784
  },
  {
    id: 2,
    title: "Barcelona, Spain",
    content: "travel.cardContentBarcelona",
    imageUrl: "/Barcelone.jpg",
    city: "Barcelona",
    lat: 41.38879,
    lon: 2.15899
  },
  {
    id: 3,
    title: "Lyon, France",
    content: "travel.cardContentLyon",
    imageUrl: "/Lyon.png",
    city: "Lyon",
    lat: 45.76342,
    lon: 4.834277
  },
  {
    id: 4,
    title: "Paris, France",
    content: "travel.cardContentParis",
    imageUrl: "/paris.jpg",
    city: "Paris",
    lat: 48.864716,
    lon: 2.349014
  }
];

const TravelPage: FC = () => {
  const [, /*length*/ setLength] = useState<number>(0);
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      key: "selection"
    }
  ]);
  const [city, setCity] = useState<{
    geometry: {
      coordinates: number[];
    };
    properties: {
      name: string;
    };
  } | null>(null);
  const jwt = useParams()?.jwt;
  const steps: Step[] = [
    {
      content: <h2>Welcome on Trotter Application !</h2>,
      locale: {
        skip: (
          <h4>
            <b>Skip</b>
          </h4>
        ),
        next: <p>Next</p>
      },
      placement: "center",
      target: "body"
    },
    {
      content: <h2>Enter your future destination here</h2>,
      target: "#guided-tour-city"
    },
    {
      content: <h2>Set the dates of your travel</h2>,
      target: "#guided-tour-dates"
    },
    {
      content: <h2>Click on this button and voila ! Your trip is created</h2>,
      target: "#guided-tour-search-button"
    }
  ];

  const run = localStorage.getItem("GT_OVER") !== "true";
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const { t } = useTranslation();
  const [, fetchUser] = useFetchUser();

  const handleResearchButton = () => {
    if (isLoggedIn) {
      console.log(range[0], city);
      if (city) {
        dispatch({
          type: "SEARCH",
          payload: {
            place: {
              cityName: city.properties.name,
              lat: city.geometry.coordinates[1],
              lon: city.geometry.coordinates[0],
              startDate: range[0].startDate,
              endDate: range[0].endDate
            }
          }
        });
      }
      navigate("/map");
    } else {
      navigate("/login");
    }
  };

  const [jsonData, setJsonData] = useState<{
    features: {
      geometry: {
        coordinates: number[];
      };
      properties: {
        name: string;
      };
    }[];
  } | null>(null);

  useEffect(() => {
    if (jwt) {
      dispatch({
        type: "SET_USER",
        payload: {
          token: jwt
        }
      });
      fetchUser();
    }
    const fetchData = async () => {
      // Fetch the JSON file using the relative path
      const response = await fetch("/data.geojson");
      const data = await response.json();

      setJsonData(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (range.length === 0 || !range[0].endDate || !range[0].startDate) return;
    const diffTime = Math.abs(
      range[0]?.endDate?.getTime() - range[0]?.startDate?.getTime()
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    setLength(diffDays);
  }, [range]);

  const isLoggedIn = useSelector(getUserToken);

  const mapRef = useRef<MapRef | null>(null);

  useEffect(() => {
    const rotateGlobe = (timestamp: number) => {
      if (mapRef.current) {
        const bearing = (timestamp / 300) % 360;
        mapRef.current.setBearing(bearing);
      }
      requestAnimationFrame(rotateGlobe);
    };
    requestAnimationFrame(rotateGlobe);
  }, []);

  return (
    <div>
      <TravelWrapper container p={0} m={0} rowGap={10}>
        <Joyride
          showProgress
          showSkipButton
          continuous
          steps={steps}
          scrollOffset={600}
          styles={{
            options: {
              arrowColor: COLORS.blue,
              backgroundColor: COLORS.bg,
              textColor: COLORS.text,
              primaryColor: COLORS.blue
            },
            buttonClose: {
              display: "none"
            }
          }}
          callback={(data: CallBackProps) => {
            if (data.status === "finished" || data.action === "skip") {
              localStorage.setItem("GT_OVER", "true");
            }
          }}
          run={run}
        />
        <Grid item p={0} m={0} xs={12}>
          <WithHeader isLoggedIn={isLoggedIn !== undefined} />
        </Grid>
      </TravelWrapper>
      <MapContainer>
        <Map
          ref={mapRef}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          projection={"globe"}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          interactive={false}
          initialViewState={{
            latitude: 80,
            longitude: 90,
            zoom: 2,
            bearing: 0,
            pitch: 60
          }}
        />
        <BlackEffect />
        <DestinationContainer>
          <ChooseDestination>
            <p>{t("travel.title")}</p>
            <br />
            <Autocomplete
              data-testid="cityName"
              id="guided-tour-city"
              disablePortal
              options={jsonData?.features ?? []}
              getOptionLabel={(option) => option.properties.name}
              sx={{ width: 350 }}
              isOptionEqualToValue={(option, value) =>
                option.properties.name === value.properties.name
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setCity(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("travel.inputLabel")}
                  key={params.id}
                />
              )}
            />
            <SpaceDate />
            <DatePicker.RangePicker
              size={"middle"}
              style={{
                backgroundColor: `${COLORS.bg}`,
                borderColor: "#bbbbbb",
                borderRadius: "5px"
              }}
              placement="bottomLeft"
              id="guided-tour-dates"
              onChange={(rv) => {
                if (!rv || !rv[0] || !rv[1]) return;
                if (!rv[0]?.isBefore(rv[1]?.add(-7, "day"))) {
                  setRange([
                    {
                      startDate: rv[0].toDate(),
                      endDate: rv[1].toDate(),
                      key: "selection"
                    }
                  ]);
                }
              }}
              disabledDate={(date) => date.toDate() < new Date()}
              value={[dayjs(range[0].startDate), dayjs(range[0].endDate)]}
            />
            <SearchButton
              id="guided-tour-search-button"
              onClick={() => {
                handleResearchButton();
              }}
              data-testid="goOnTrip"
            >
              <div>{t("travel.searchButton")}</div>
            </SearchButton>
            <br />
          </ChooseDestination>
        </DestinationContainer>
      </MapContainer>
      <DestinationComponentWrapper>
        <TitleRecommended>{t("travel.recommended")}</TitleRecommended>
        <RecommendedWrapper>
          {cardData.map((card) => (
            <StyledCard key={card.id}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  sx={{ height: 250 }}
                  image={card.imageUrl}
                  alt={`${card.title} Image`}
                  onClick={() => {
                    handleResearchButton();
                  }}
                />
                <CardContent>
                  <h3>{card.title}</h3>
                  <p>{t(card.content)}</p>
                </CardContent>
              </CardActionArea>
            </StyledCard>
          ))}
        </RecommendedWrapper>
      </DestinationComponentWrapper>
      <Grid item p={0} m={0} xs={12}>
        <WithFooter />
      </Grid>
    </div>
  );
};

const TravelWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MapContainer = styled.div`
  width: 100% !important;
  height: 100vh;
  position: relative;
  top: 0;
  background-color: black;
  z-index: 1;
`;

const DestinationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const BlackEffect = styled.div`
  background-color: ${COLORS.text};
  opacity: 0.3;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const DestinationComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 42px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const SpaceDate = styled.div`
  height: 18px;
  width: 100%;
`;

const ChooseDestination = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-weight: bold;
  font-size: 30px;
  font-family: ${FONT};
  color: ${COLORS.text};
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${COLORS.bg};
  border-radius: 10px;
  padding: 22px;
  margin-top: 5px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 5px;
  }
`;

const SearchButton = styled.button`
  width: 180px;
  height: 5.2vh;
  z-index: 1;
  position: relative;
  margin-top: 36px;

  background-color: ${COLORS.blue};
  border: none !important;
  border-radius: 10px;
  color: ${COLORS.white};
  box-shadow: 3px 2px 10px rgba(${COLORS.text}, 0.1);
  cursor: pointer;
  font-weight: bolder;
  font-size: 20px;
  font-family: ${FONT};

  &:hover {
    background-color: ${COLORS.blueGreen};
    transition-duration: 0.4s;
  }
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 15px;
  }
`;

const TitleRecommended = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
`;

const RecommendedWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px 0;
  margin: 20px;

  @media screen and (max-width: 1200px) {
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledCard = styled(Card)`
  flex: 0 0 calc(25% - 20px);

  @media screen and (max-width: 1200px) {
    flex: 0 0 calc(33.33% - 20px);
  }
  @media (max-width: 1024px) {
    margin 0 10px 20px 10px;
  }

  @media screen and (max-width: 768px) {
    flex: 0 0 calc(100% - 20px);
    margin: 0 10px 20px 10px;
  }
`;

export default TravelPage;
