import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import "./Profile.scss";
import { COLORS } from "../../UI/Colors";
import styled from "styled-components";

// import WithHeader from "../../Layout/WithHeader";
import WithFooter from "../../Layout/WithFooter";
import { Avatar, Button, Checkbox, Divider } from "antd";
import ModificableInput from "../../components/ModificableInput";
import { useSelector } from "react-redux";
import { getSavedTrips } from "../../reducers/trips.reducers";
import { useGetTrips } from "../../hooks/useGetTrips";
import { SaveAltRounded } from "@mui/icons-material";
import MapPreview from "./MapPreview";
import { format } from "date-fns";
import { User, getUser } from "../../reducers/auth.reducers";
import { useEditUser } from "../../hooks/useEditUser";
import { useFetchUser } from "../../hooks/useFetchUser";
import FriendsGrid from "./Friends/Friends";
import { title } from "process";
import { feature } from "@turf/helpers";
import { Delete } from "@mui/icons-material";
import { useDeleteTrip } from "../../hooks/useDeleteTrip";
import LogoutButton from "../../components/Profile/LogoutButton";

const Profile = () => {
  const trips = useSelector(getSavedTrips);
  const originalUser = useSelector(getUser);
  const [getTripsStatus, getTrips] = useGetTrips();
  const [, fetchUser] = useFetchUser();

  const [editUserStatus, editUser] = useEditUser();
  const [deleteTripStatus, deleteTrip] = useDeleteTrip();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [user, setUser] = useState(originalUser);
  const [interests, setInterests] = useState<string[]>(
    localStorage.getItem("interests")?.split(",") || []
  );
  const navigate = useNavigate();

  let originalInterests = localStorage.getItem("interests");

  const interestsName: string[] = [
    "Religion",
    "Event",
    "Nature",
    "Museum",
    "Art",
    "Music",
    "Night Life",
    "Street Art",
    "Monument"
  ];

  const { t } = useTranslation();

  const handleChange =
    (key: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUser((prev) => ({ ...prev, [key]: event.target.value }));
    };

  const handleDeleteTrip = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    await deleteTrip(id);
  };

  useEffect(() => {
    // get trips anyway, loss is not that big
    getTrips();
    fetchUser();
  }, []);

  useEffect(() => {
    console.log(originalUser);
    setUser(originalUser);
  }, [originalUser]);

  console.log(user);

  return (
    <>
      <div>
        <div className="profileLayout">
          {user && (
            <>
              <Box>
                <CardProfile>
                  <CardContentInfos>
                    <AvatarContainer>
                      <AvatarStyle
                        className="avatar"
                        src={`https://api.dicebear.com/8.x/notionists-neutral/svg?seed=${localStorage.getItem(
                          user.email
                        )}`}
                      />
                    </AvatarContainer>
                    <SpaceDivider />
                    <ModificableInput
                      value={user.id}
                      onChange={handleChange("email")}
                      label="ID"
                    />
                    <ModificableInput
                      value={user.username}
                      onChange={handleChange("username")}
                      label="Username"
                      editable
                    />
                    <ModificableInput
                      value={user.email}
                      onChange={handleChange("email")}
                      label="EMail"
                    />
                    <h4>{t("profile.interests")}</h4>
                    <div className="interestsSettings">
                      <Checkbox.Group
                        options={interestsName}
                        defaultValue={interests}
                        onChange={(values) => {
                          setInterests(values as string[]);
                        }}
                      />
                    </div>
                    <ActionButtons>
                      <SaveChangesButton
                        type="primary"
                        disabled={
                          !(
                            user.birthDate !== originalUser.birthDate ||
                            user.email !== originalUser.email ||
                            user.username !== originalUser.username ||
                            user.phoneNumber !== originalUser.phoneNumber
                          ) && interests.join(",") === originalInterests
                        }
                        loading={editUserStatus.loading}
                        onClick={() => {
                          localStorage.setItem(
                            "interests",
                            interests.join(",")
                          );
                          originalInterests = interests.join(",");
                          editUser(user);
                        }}
                      >
                        {t("profile.saveChanges")}
                      </SaveChangesButton>
                      {/* <Button danger>{t("profile.changePassword")}</Button> */}
                      <LogoutButton />
                      <DeleteAccountButton
                        type="primary"
                        danger
                        onClick={() => {
                          setShowDelete(true);
                        }}
                      >
                        {t("profile.deleteAccount")}
                      </DeleteAccountButton>
                    </ActionButtons>
                  </CardContentInfos>
                </CardProfile>
              </Box>
            </>
          )}
          <SavedTripsList>
            <h3>{t("profile.savedTrips")}</h3>
            <Divider />
            <CardContent>
              {getTripsStatus.loading ? (
                <>
                  <CircularProgress />
                </>
              ) : (
                <>
                  {trips.length ? (
                    <TripsGrid>
                      {trips.map((trip, i) => {
                        const startDate = new Date(0).setUTCSeconds(
                          trip.startDate / 1000
                        );
                        const endDate = new Date(0).setUTCSeconds(
                          trip.endDate / 1000
                        );
                        return (
                          <div className="previewContainer" key={i}>
                            <div
                              className="tripInfo"
                              onClick={() => {
                                navigate(`/map?id=${trip.id}`);
                              }}
                            >
                              <span>{trip.cityName}</span>
                              <span>
                                {format(startDate, "dd MMM") +
                                  " ➤ " +
                                  format(endDate, "dd MMM")}
                              </span>
                              <IconButton
                                color="inherit"
                                onClick={(e) => handleDeleteTrip(e, trip.id)}
                                aria-label="close"
                                className="deleteTripButton"
                              >
                                <Delete />
                              </IconButton>
                            </div>
                            <MapPreview trip={trip} />
                          </div>
                        );
                      })}
                    </TripsGrid>
                  ) : (
                    <div className="emptyTripList">
                      <SaveAltRounded
                        sx={{ height: 100, width: 100, color: "lightgray" }}
                      />
                      <p>{t("profile.findYourSavedTripsHere")}</p>
                    </div>
                  )}
                </>
              )}
            </CardContent>
          </SavedTripsList>
        </div>
        <FriendsGrid />
      </div>
      <DeleteAccountModal
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
      <WithFooter />
    </>
  );
};

const CardProfile = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  padding: 12px 20px;
`;

const CardContentInfos = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AvatarStyle = styled(Avatar)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid lightgray;
`;

const AvatarContainer = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  margin: 20px 12px 0px 12px;
  gap: 6px;
`;

const SaveChangesButton = styled(Button)`
  background-color: ${COLORS.blue};
`;

const DeleteAccountButton = styled(Button)`
  background-color: crimson !important;
`;

const TripsGrid = styled.div`
  display: grid;
  grid-template: auto 1fr / auto auto auto;
  width: 100%;
  gap: 12px;
  margin: 24px;
  color: white;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const SavedTripsList = styled(Card)`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  padding: 20px;
`;

const SpaceDivider = styled(Divider)`
  margin: 6px 0;
`;

export default Profile;
