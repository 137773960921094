import React, { FC } from "react";
import WithHeader from "../../Layout/WithHeader";
import { useSelector } from "react-redux";
import { getUserToken } from "../../reducers/auth.reducers";

const Policy: FC = () => {
  const isLoggedIn = useSelector(getUserToken);

  return (
    <div>
      <WithHeader isLoggedIn={isLoggedIn !== undefined} />
      <p className="c2">
        <span className="c7 c1"></span>
      </p>
      <p className="c10 c30 title" id="h.fbv4xhagrtbz">
        <span className="c7 c19">PRIVACY POLICY</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">Last updated June 0</span>
        <span>1</span>
        <span className="c7 c1">, 2024</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          This privacy notice for Trotter (&quot;we,&quot; &quot;us,&quot; or
          &quot;our&quot;), describes how and why we might collect, store, use,
          and/or share (&quot;process&quot;) your information when you use our
          services (&quot;Services&quot;), such as when you:
        </span>
      </p>
      <ul className="c9 lst-kix_hrddbhxqoh1s-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Visit our website at </span>
          <span className="c3 c14">
            <a className="c8" href={`${process.env.REACT_APP_URI}/privacy`}>
              {process.env.REACT_APP_URI}
            </a>
          </span>
          <span className="c7 c1">
            , or any website of ours that links to this privacy notice
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_eokf9zmsxw64-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Download and use our mobile application (Trotter), or any other
            application of ours that links to this privacy notice
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_pej9kn65i9sr-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Engage with us in other related ways, including any sales,
            marketing, or events
          </span>
        </li>
      </ul>
      <p className="c6">
        <span className="c7 c1">
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          trotter.forward@gmail.com.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <hr />
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.3b98y6e8n451">
        <span className="c7 c21">SUMMARY OF KEY POINTS</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c20 c5">
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          What personal information do we process? When you visit, use, or
          navigate our Services, we may process personal information depending
          on how you interact with us and the Services, the choices you make,
          and the products and features you use.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          Do we collect any information from third parties? We do not collect
          any information from third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          How do we keep your information safe? We have organizational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c7 c20">
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c20"></span>
      </p>
      <p className="c6">
        <span className="c20">
          How do you exercise your rights? The easiest way to exercise your
          rights is by visiting{" "}
        </span>
        <span className="c12">
          <a className="c8" href={`${process.env.REACT_APP_URI}/privacy`}>
            {process.env.REACT_APP_URI}/privacy
          </a>
        </span>
        <span className="c7 c20">
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </span>
      </p>
      <p className="c2">
        <span className="c7 c20"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <hr />
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.z3wvex3qt0y5">
        <span className="c7 c21">TABLE OF CONTENTS</span>
      </h1>
      <p className="c2">
        <span className="c1 c17"></span>
      </p>
      <p className="c2">
        <span className="c7 c1"></span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.3b98y6e8n451">
            SUMMARY OF KEY
            POINTS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c3 c11">
          <a className="c8" href="#h.z3wvex3qt0y5">
            TABLE OF CONTENTS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.ai4yj1i8ajds">
            WHAT INFORMATION DO WE
            COLLECT?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4
          </a>
        </span>
      </p>
      <p className="c23 c27">
        <span className="c7 c3 c25">
          <a className="c8" href="#h.n0ocj5j5xe4i">
            Personal information you disclose to
            us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4
          </a>
        </span>
      </p>
      <p className="c23 c27">
        <span className="c7 c3 c25">
          <a className="c8" href="#h.bsnnl9m26u9z">
            Information automatically
            collected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.ua1miow2mva0">
            HOW DO WE PROCESS YOUR
            INFORMATION?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.7l5irhnen83">
            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
            INFORMATION?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.c1fj2fw7ngjq">
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
            INFORMATION?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.240r0xtin1dd">
            WHAT IS OUR STANCE ON THIRD-PARTY
            WEBSITES?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.a2sreh86hk86">
            DO WE USE COOKIES AND OTHER TRACKING
            TECHNOLOGIES?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.hpk3kmaoc4mk">
            HOW DO WE HANDLE YOUR SOCIAL
            LOGINS?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.pe3co1mrr9f0">
            HOW LONG DO WE KEEP YOUR
            INFORMATION?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.r89zu6t1kxzi">
            HOW DO WE KEEP YOUR INFORMATION
            SAFE?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.90iw0hvjp31b">
            WHAT ARE YOUR PRIVACY
            RIGHTS?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11
          </a>
        </span>
      </p>
      <p className="c23 c27">
        <span className="c7 c3 c25">
          <a className="c8" href="#h.xxots0xvbr8m">
            Account
            Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.y9m15fve5qz1">
            CONTROLS FOR DO-NOT-TRACK
            FEATURES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.v80mvmji72px">
            DO WE MAKE UPDATES TO THIS
            NOTICE?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.5ynj14bq4n09">
            HOW CAN YOU CONTACT US ABOUT THIS
            NOTICE?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13
          </a>
        </span>
      </p>
      <p className="c23">
        <span className="c11 c3">
          <a className="c8" href="#h.ne1hwcy613rh">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13
          </a>
        </span>
      </p>
      <p className="c2">
        <span className="c7 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <hr />
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.ai4yj1i8ajds">
        <span className="c7 c21">WHAT INFORMATION DO WE COLLECT?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h2 className="c6 c10" id="h.n0ocj5j5xe4i">
        <span className="c4">Personal information you disclose to us</span>
      </h2>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We collect personal information that you provide to us.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </span>
      </p>
      <ul className="c9 lst-kix_lxpljc7zogbz-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">names</span>
        </li>
      </ul>
      <ul className="c9 lst-kix_6b4udjlobfzi-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">phone numbers</span>
        </li>
      </ul>
      <ul className="c9 lst-kix_5ji3tnyitxt4-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">email addresses</span>
        </li>
      </ul>
      <ul className="c9 lst-kix_9989u8nuejp2-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">usernames</span>
        </li>
      </ul>
      <ul className="c9 lst-kix_r0lcvasphbwk-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">passwords</span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c7 c1">birthdates</span>
        </li>
      </ul>
      <p className="c6">
        <span className="c7 c1">
          Sensitive Information. We do not process sensitive information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">
          Social Media Login Data. We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, X, or other social media account. If you choose to
          register in this way, we will collect certain profile information
          about you from the social media provider, as described in the section
          called &quot;
        </span>
        <span className="c3">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
        <span className="c7 c1">&quot; below.</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">Application Data.</span>
        <span className="c7 c16">
          &nbsp;If you use our application(s), we also may collect the following
          information if you choose to provide us with access or permission:
        </span>
      </p>
      <ul className="c9 lst-kix_ju86a6vp5x1e-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Geolocation Information.</span>
          <span className="c7 c16">
            &nbsp;We may request access or permission to track location-based
            information from your mobile device, either continuously or while
            you are using our mobile application(s), to provide certain
            location-based services. If you wish to change our access or
            permissions, you may do so in your device&#39;s settings.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_wu1mfe1j5kip-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Mobile Device Data.</span>
          <span className="c7 c1">
            &nbsp;We may automatically collect device information (such as your
            mobile device ID, model, and manufacturer), operating system,
            version information and system configuration information, device and
            application identification numbers, browser type and version,
            hardware model Internet service provider and/or mobile carrier, and
            Internet Protocol (IP) address (or proxy server). If you are using
            our application(s), we may also collect information about the phone
            network associated with your mobile device, your mobile
            device&rsquo;s operating system or platform, the type of mobile
            device you use, your mobile device&rsquo;s unique device ID, and
            information about the features of our application(s) you accessed.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_oix3iy5wx6sz-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Push Notifications.</span>
          <span className="c7 c1">
            &nbsp;We may request to send you push notifications regarding your
            account or certain features of the application(s). If you wish to
            opt out from receiving these types of communications, you may turn
            them off in your device&#39;s settings.
          </span>
        </li>
      </ul>
      <p className="c6">
        <span className="c7 c1">
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h2 className="c6 c10" id="h.bsnnl9m26u9z">
        <span className="c4">Information automatically collected</span>
      </h2>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: Some information &mdash; such as your Internet Protocol (IP)
          address and/or browser and device characteristics &mdash; is collected
          automatically when you visit our Services.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">The information we collect includes:</span>
      </p>
      <ul className="c9 lst-kix_m7us8niagwff-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Log and Usage Data.</span>
          <span className="c1">
            &nbsp;Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you
            access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may include
            your IP address, device information, browser type, and settings and
            information about your activity in the Services
          </span>
          <span className="c16">&nbsp;</span>
          <span className="c7 c1">
            (such as the date/time stamps associated with your usage, pages and
            files viewed, searches, and other actions you take such as which
            features you use), device event information (such as system
            activity, error reports (sometimes called &quot;crash dumps&quot;),
            and hardware settings).
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_nptw3oilehsd-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Device Data.</span>
          <span className="c7 c1">
            &nbsp;We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_2xxj78j3arsq-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c5">Location Data.</span>
          <span className="c7 c1">
            &nbsp;We collect location data such as information about your
            device&#39;s location, which can be either precise or imprecise. How
            much information we collect depends on the type and settings of the
            device you use to access the Services. For example, we may use GPS
            and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.ua1miow2mva0">
        <span className="c7 c21">HOW DO WE PROCESS YOUR INFORMATION?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </span>
      </p>
      <ul className="c9 lst-kix_j7f0yygu0r6-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_g8uuo3rd8zmz-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            To deliver and facilitate delivery of services to the user. We may
            process your information to provide you with the requested service.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_8vjdtudqorlo-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1 c7">
            To respond to user inquiries/offer support to users. We may process
            your information to respond to your inquiries and solve any
            potential issues you might have with the requested service.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_f9olfjoigqe8-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            To enable user-to-user communications. We may process your
            information if you choose to use any of our offerings that allow for
            communication with another user.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_rllmj9dzzeb5-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            To request feedback. We may process your information when necessary
            to request feedback and to contact you about your use of our
            Services.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_ahce50y7iksl-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">To protect our Services.</span>
          <span className="c7 c16">
            &nbsp;We may process your information as part of our efforts to keep
            our Services safe and secure, including fraud monitoring and
            prevention.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_8u355bpka89g-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">To identify usage trends.</span>
          <span className="c7 c16">
            &nbsp;We may process information about how you use our Services to
            better understand how they are being used so we can improve them.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_jgg01yrhlm1g-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">
            To save or protect an individual&#39;s vital interest.
          </span>
          <span className="c7 c16">
            &nbsp;We may process your information when necessary to save or
            protect an individual&rsquo;s vital interest, such as to prevent
            harm.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.7l5irhnen83">
        <span className="c7 c21">
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1 c5 c24">
          In Short: We only process your personal information when we believe it
          is necessary and we have a valid legal reason (i.e., legal basis) to
          do so under applicable law, like with your consent, to comply with
          laws, to provide you with services to enter into or fulfill our
          contractual obligations, to protect your rights, or to fulfill our
          legitimate business interests.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </span>
      </p>
      <ul className="c9 lst-kix_ah5olealtvn-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Consent. We may process your information if you have given us
            permission (i.e., consent) to use your personal information for a
            specific purpose. You can withdraw your consent at any time.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_81bkepwbv7o7-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Performance of a Contract.</span>
          <span className="c7 c16">
            &nbsp;We may process your personal information when we believe it is
            necessary to fulfill our contractual obligations to you, including
            providing our Services or at your request prior to entering into a
            contract with you.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_1sq3ypbepdhz-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Legitimate Interests.</span>
          <span className="c7 c16">
            &nbsp;We may process your information when we believe it is
            reasonably necessary to achieve our legitimate business interests
            and those interests do not outweigh your interests and fundamental
            rights and freedoms. For example, we may process your personal
            information for some of the purposes described in order to:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_9woaj83rbvhs-0 start">
        <li className="c15 li-bullet-0">
          <span className="c7 c16">
            Analyze how our Services are used so we can improve them to engage
            and retain users
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_m9wbe9z7b2p3-0 start">
        <li className="c15 li-bullet-0">
          <span className="c7 c16">
            Diagnose problems and/or prevent fraudulent activities
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_bpgsasagxm83-0 start">
        <li className="c15 li-bullet-0">
          <span className="c7 c16">
            Understand how our users use our products and services so we can
            improve user experience
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_edx2ydi69mz4-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Legal Obligations.</span>
          <span className="c7 c16">
            &nbsp;We may process your information where we believe it is
            necessary for compliance with our legal obligations, such as to
            cooperate with a law enforcement body or regulatory agency, exercise
            or defend our legal rights, or disclose your information as evidence
            in litigation in which we are involved.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_bq9kvxts3c4f-0 start">
        <li className="c0 li-bullet-0">
          <span className="c1">Vital Interests.</span>
          <span className="c7 c16">
            &nbsp;We may process your information where we believe it is
            necessary to protect your vital interests or the vital interests of
            a third party, such as situations involving potential threats to the
            safety of any person.
          </span>
        </li>
      </ul>
      <h1 className="c6 c10 c18" id="h.8cueyspi36pv">
        <span className="c7 c21"></span>
      </h1>
      <h1 className="c6 c10" id="h.c1fj2fw7ngjq">
        <span className="c7 c21">
          WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We may need to share your personal information in the following
          situations:
        </span>
      </p>
      <ul className="c9 lst-kix_ej3657wau44-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_ecks28igmsau-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Other Users. When you share personal information (for example, by
            posting comments, contributions, or other content to the Services)
            or otherwise interact with public areas of the Services, such
            personal information may be viewed by all users and may be publicly
            made available outside the Services in perpetuity. If you interact
            with other users of our Services and register for our Services
            through a social network (such as Facebook), your contacts on the
            social network will see your name, profile photo, and descriptions
            of your activity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our
            Services, and view your profile.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <hr />
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.240r0xtin1dd">
        <span className="c7 c21">
          WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We are not responsible for the safety of any information
          that you share with third parties that we may link to or who advertise
          on our Services, but are not affiliated with, our Services.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.a2sreh86hk86">
        <span className="c7 c21">
          DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </span>
      </p>
      <h1 className="c6 c10" id="h.hpk3kmaoc4mk">
        <span className="c7 c21">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: If you choose to register or log in to our Services using a
          social media account, we may have access to certain information about
          you.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </span>
      </p>
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.pe3co1mrr9f0">
        <span className="c7 c21">HOW LONG DO WE KEEP YOUR INFORMATION?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.r89zu6t1kxzi">
        <span className="c7 c21">HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.90iw0hvjp31b">
        <span className="c7 c21">WHAT ARE YOUR PRIVACY RIGHTS?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: In some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), and Switzerland, you have rights that allow you
          greater access to and control over your personal information. You may
          review, change, or terminate your account at any time, depending on
          your country, province, or state of residence.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          In some regions (like the EEA, UK, and Switzerland), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT
          THIS NOTICE?&quot; below.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </span>
      </p>
      <p className="c6">
        <span className="c17 c16">&nbsp;</span>
      </p>
      <p className="c6">
        <span className="c1">
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your{" "}
        </span>
        <span className="c14 c22">
          <a
            className="c8"
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1727358760354455&amp;usg=AOvVaw1hUzqtmf4uZnJMpr7p9dBQ"
          >
            Member State data protection authority
          </a>
        </span>
        <span className="c1">&nbsp;or </span>
        <span className="c3 c14">
          <a
            className="c8"
            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1727358760354751&amp;usg=AOvVaw3fOXg7EUGTrKXexKRvY1CD"
          >
            UK data protection authority
          </a>
        </span>
        <span className="c7 c1">.</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">
          If you are located in Switzerland, you may contact the{" "}
        </span>
        <span className="c3 c14">
          <a
            className="c8"
            href="https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1727358760355073&amp;usg=AOvVaw3ncmoN3U7GHHeZKL_sB3jy"
          >
            Federal Data Protection and Information Commissioner
          </a>
        </span>
        <span className="c7 c1">.</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c26 c1">Withdrawing your consent:</span>
        <span className="c7 c1">
          &nbsp;If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          You can withdraw your consent at any time by contacting us by using
          the contact details provided in the section &quot;HOW CAN YOU CONTACT
          US ABOUT THIS NOTICE?&quot; below.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h2 className="c6 c10" id="h.xxots0xvbr8m">
        <span className="c4">Account Information</span>
      </h2>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c16">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </span>
      </p>
      <ul className="c9 lst-kix_fb70ld6kieho-0 start">
        <li className="c0 li-bullet-0">
          <span className="c7 c1">
            Log in to your account settings and update your user account.
          </span>
        </li>
      </ul>
      <p className="c6">
        <span className="c7 c1">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1 c26">Cookies and similar technologies:</span>
        <span className="c1">
          &nbsp;Most Web browsers are set to accept cookies by default. If you
          prefer, you can usually choose to set your browser to remove cookies
          and to reject cookies. If you choose to remove cookies or reject
          cookies, this could affect certain features or services of our
          Services. You may also{" "}
        </span>
        <span className="c16">
          opt out of interest-based advertising by advertisers
        </span>
        <span className="c7 c1">&nbsp;on our Services.</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c16">
          If you have questions or comments about your privacy rights, you may
          email us at
        </span>
        <span className="c1">trotter.forward@gmail.com</span>
        <span className="c7 c16">.</span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <hr />
      <p className="c2">
        <span className="c7 c21"></span>
      </p>
      <h1 className="c6 c10" id="h.y9m15fve5qz1">
        <span className="c7 c21">CONTROLS FOR DO-NOT-TRACK FEATURES</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&quot;DNT&quot;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online browsing activities monitored and collected. At
          this stage, no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a revised version of
          this privacy notice.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.v80mvmji72px">
        <span className="c7 c21">DO WE MAKE UPDATES TO THIS NOTICE?</span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c24 c1 c5">
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c7 c1">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &quot;Revised&quot; date at
          the top of this privacy notice. If we make material changes to this
          privacy notice, we may notify you either by prominently posting a
          notice of such changes or by directly sending you a notification. We
          encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.
        </span>
      </p>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <h1 className="c6 c10" id="h.5ynj14bq4n09">
        <span className="c7 c21">
          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span>
          If you have questions or comments about this notice, you may email us
          at{" "}
        </span>
        <span className="c13">
          <a className="c8" href="mailto:trotter.forward@gmail.com">
            trotter.forward@gmail.com
          </a>
        </span>
        <span className="c7 c1">.</span>
      </p>
      <h1 className="c6 c10" id="h.ne1hwcy613rh">
        <span className="c7 c21">
          <br />
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </span>
      </h1>
      <p className="c2">
        <span className="c17 c1"></span>
      </p>
      <p className="c6">
        <span className="c1">
          You have the right to request access to the personal information we
          collect from you, details about how we have processed it, correct
          inaccuracies, or delete your personal information. You may also have
          the right to withdraw your consent to our processing of your personal
          information. These rights may be limited in some circumstances by
          applicable law. To request to review, update, or delete your personal
          information, please visit:
        </span>
        <span>&nbsp;</span>
        <span className="c14">
          <a className="c8" href={`${process.env.REACT_APP_URI}/privacy`}>
            {process.env.REACT_APP_URI}
          </a>
        </span>
        <span className="c3 c14">
          <a className="c8" href={`${process.env.REACT_APP_URI}/privacy`}>
            /privacy
          </a>
        </span>
        <span className="c29">&nbsp;</span>
        <span className="c1">or contact us at</span>
        <span className="c3">&nbsp;</span>
        <span className="c1">trotter.forward@gmail.com.</span>
      </p>
    </div>
  );
};

export default Policy;
