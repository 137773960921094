import React from "react";
import "./WithHeaders.scss";
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../UI/Colors";

type WithFooterProps = {
  half?: boolean;
};

type NavButtons = {
  key: string;
  redirect: string;
}[];

const navButtons: NavButtons = [
  {
    key: "Privacy policy",
    redirect: "/privacy"
  },
  {
    key: "Terms of services",
    redirect: "/tos"
  }
];

const WithFooter = ({ half = false }: WithFooterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FooterContainer
      half={!useMediaQuery(theme.breakpoints.down("lg")) && half}
      title="footer"
    >
      <ButtonsContainer>
        {navButtons.map((button) => (
          <button
            type="button"
            key={button.key}
            onClick={() => navigate(button.redirect)}
          >
            <Typography variant="button">
              {t(button.key).toUpperCase()}
            </Typography>
          </button>
        ))}
      </ButtonsContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer<WithFooterProps>`
  width: ${(props) => (props.half ? "calc(50% - 40px)" : "calc(100% - 40px)")};
  height: 48px;
  background-color: ${COLORS.light};
  padding: 0 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  user-select: none;
  border: 1px solid lightgray;
  top: 0;
  z-index: 100;
`;

const ButtonsContainer = styled.div`
  flex: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    &:hover {
      transform: translateY(1px);
      scale: 1.04;
      color: ${COLORS.blue};
    }
    transition: transform 0.1s ease-in-out 0s;
  }
`;

export default WithFooter;
